import React from "react";
import g5 from "../../assets/img/photos/g5.jpg";
import g6 from "../../assets/img/photos/g6.jpg";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// var settings = {
//   dots: true,
//   infinite: true,
//   speed: 1000,
//   slidesToShow: 1,
//   slidesToScroll: 1,
// };

function Testimonial() {
  // swiper-slide swiper-slide-active
  // swiper-slide swiper-slide-next
  // swiper-slide swiper-slide-prev
  // swiper-slide

  return (
    <>
      <section className="wrapper bg-light">
        <div className="container py-14 py-md-17 pb-0 pb-md-0">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="fs-16 text-uppercase text-muted mb-3">
                HAPPY CUSTOMERS
              </h2>
              <h3 className="display-4 mb-10 px-xl-10">
                Don't take our word for it. See what customers are saying about
                us.
              </h3>
            </div>
            {/* /column */}
          </div>

          <div className="row gx-lg-8 gx-xl-12 gy-6  align-items-center">
            <div className="col-lg-7 position-relative">
              <div
                className="shape bg-dot primary rellax w-18 h-18"
                // data-rellax-speed={1}
                style={{ top: 0, left: "-1.4rem", zIndex: 0 }}
              />
              <div className="row gx-md-5 gy-5">
                <div className="col-md-6">
                  <figure className="rounded mt-md-10 position-relative">
                    <img
                      src={g5}
                      //   srcSet="./assets/img/photos/g5@2x.jpg 2x"
                      alt=""
                    />
                  </figure>
                </div>
                {/*/column */}
                <div className="col-md-6">
                  <div className="row gx-md-5 gy-5">
                    <div
                      className="col-md-12 order-md-2"
                      //   data-cue="fadeIn"
                      //   data-delay={900}
                    >
                      <figure className="rounded">
                        <img
                          src={g6}
                          srcSet="./assets/img/photos/g6@2x.jpg 2x"
                          alt=""
                        />
                      </figure>
                    </div>
                    {/*/column */}
                    <div
                      className="col-md-10"
                      //   data-cue="fadeIn"
                      //   data-delay={300}
                    >
                      <div className="card bg-pale-primary text-center">
                        <div className="card-body py-11 counter-wrapper">
                          <h3 className="counter text-nowrap">25+</h3>
                          <p className="mb-0">Satisfied Customers</p>
                        </div>
                        {/*/.card-body */}
                      </div>
                      {/*/.card */}
                    </div>
                    {/*/column */}
                  </div>
                  {/*/.row */}
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/*/column */}
            <div className="col-lg-5 mt-5">
              <div
                className="swiper-container dots-closer mb-6"
                data-margin={30}
                data-dots="true"
              >
                <div className="swiper">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <blockquote className="icon icon-top fs-lg text-center">
                        <p>
                          “ Dobedo Soft was incredibly professional and
                          attentive throughout our software development project.
                          Their attention to detail and willingness to go above
                          and beyond exceeded my expectations. I highly
                          recommend their services! ”
                        </p>
                        <div className="blockquote-details justify-content-center text-center">
                          <div className="info ps-0">
                            <h5 className="mb-1">- Ariane</h5>
                            {/* <p className="mb-0">Financial Analyst</p> */}
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    {/*/.swiper-slide */}
                    <div className="swiper-slide swiper-slide-next">
                      <blockquote className="icon icon-top fs-lg text-center">
                        <p>
                          “Vivamus sagittis lacus vel augue laoreet rutrum
                          faucibus dolor auctor. Vestibulum ligula porta felis
                          euismod semper. Cras justo odio consectetur.”
                        </p>
                        <div className="blockquote-details justify-content-center text-center">
                          <div className="info ps-0">
                            <h5 className="mb-1">Cory Zamora</h5>
                            <p className="mb-0">Marketing Specialist</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    {/*/.swiper-slide */}
                    <div className="swiper-slide">
                      <blockquote className="icon icon-top fs-lg text-center">
                        <p>
                          “Vivamus sagittis lacus vel augue laoreet rutrum
                          faucibus dolor auctor. Vestibulum ligula porta felis
                          euismod semper. Cras justo odio consectetur.”
                        </p>
                        <div className="blockquote-details justify-content-center text-center">
                          <div className="info ps-0">
                            <h5 className="mb-1">Nikolas Brooten</h5>
                            <p className="mb-0">Sales Manager</p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                    {/*/.swiper-slide */}
                  </div>
                  {/*/.swiper-wrapper */}
                </div>
                {/*/.swiper */}
              </div>
              {/* /.swiper-container */}
            </div>
            {/*/column */}
          </div>
          {/* /div */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
}

export default Testimonial;
