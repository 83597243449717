import Footer from "../components/footer";
import Contact from "../components/contact";
import Navbar from "../components/navbar";
import React from "react";
import Map from "../components/map";

const ContactUsPage = () => {
  return (
    <>
      <Navbar />
      <Contact />
      <Map />
      <Footer />
    </>
  );
};

export default ContactUsPage;
