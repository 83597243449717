import React from "react";
import Navbar from "../components/navbar";
import Privacy from "../components/privacy";
import Footer from "../components/footer";

const PrivacyPage = () => {
  return (
    <>
      <Navbar />
      <Privacy />
      <Footer />
    </>
  );
};

export default PrivacyPage;
