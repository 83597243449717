import React from "react";
import "../../assets/styles/gallery.css";
import image1 from "../../assets/img/gallery/image-1.JPG";
import image2 from "../../assets/img/gallery/image-2.jpg";
import image3 from "../../assets/img/gallery/image-3.jpeg";
import image4 from "../../assets/img/gallery/image-4.jpeg";
import image7 from "../../assets/img/gallery/image-7.jpeg";

const Gallery = () => {
  const images = [
    image2,
    image3,
    image1,
    // image5,
    // image6,
    image7,
    image4,
    // image8,
    // image9,
    // image10,
    // image11,
  ];
  return (
    <div className="grid-wrapper">
      <div className="row text-center mr-0 pr-0">
        <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h2 className="fs-16 text-uppercase text-muted mb-3 ">GALLERY</h2>
          <h3 className="display-4 mb-10 px-xl-10">
            Explore Our Digital Gallery
          </h3>
        </div>
        {/* /column */}
      </div>
      <div class="grid">
        {images.map((image, i) => {
          return (
            <div class="grid__item " key={`image.${i}`}>
              <a href={image} target="_blank">
                <img src={image} className="w-100" />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
