import React from "react";
import aboutImg from "../../assets/img/photos/about13.jpg";
import patternImg from "../../assets/img/pattern.png";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
      <section
        className="wrapper bg-dark angled lower-start"
        style={{ backgroundColor: "#1D1E25!important" }}
        id="home"
      >
        <div className="container pt-7 pt-md-11 pb-8">
          <div className="row gx-0 gy-10 align-items-center">
            <div
              className="col-lg-6"
              data-cues="slideInDown"
              data-group="page-title"
              data-delay={600}
              style={{ textAlign: "left", marginTop: "200px" }}
              data-aos="zoom-in"
            >
              <h1 className="display-1 text-white mb-4" style={{ opacity: 1 }}>
                The solution that you have dreamed of <br />
                <span
                  className="typer text-primary text-nowrap"
                  data-delay={100}
                  data-words="customer satisfaction,business needs,creative ideas"
                />
                <span className="cursor text-primary" data-owner="typer" />
              </h1>
              <p
                className="lead fs-24 lh-sm text-white mb-7 pe-md-18 pe-lg-0 pe-xxl-15"
                style={{ opacity: 1 }}
              >
                We use progressive development approach combined with modern
                tools and technologies to help you create the app you always
                wanted!
              </p>
              <div style={{ opacity: 1 }}>
                <Link
                  to="/contact-us"
                  className="btn btn-lg btn-primary rounded contact-us-button"
                  style={{ opacity: 1 }}
                >
                  Start a project
                </Link>
              </div>
            </div>
            {/* /column */}
            <div
              className="col-lg-5 offset-lg-1 mb-n18"
              data-cues="slideInDown"
              style={{ opacity: 1, marginTop: "200px" }}
              data-aos="zoom-in"
            >
              <div className="position-relative" style={{ opacity: 1 }}>
                {/* <a
                  href="assets/media/movie.mp4"
                  className="btn btn-circle btn-primary btn-play ripple mx-auto mb-6 position-absolute"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    zIndex: 3,
                  }}
                  data-glightbox=""
                >
                  <i className="icn-caret-right" />
                </a> */}
                <figure className="rounded shadow-lg">
                  <img src={aboutImg} alt="" />
                </figure>
              </div>
              {/* /div */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
}

export default Hero;
