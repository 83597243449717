import Navbar from "../components/navbar";
import About from "../components/about";
import React from "react";
import Footer from "../components/footer";

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <About />
      <Footer/>
    </>
  );
};

export default AboutUs;
