import React, { useRef, useState } from "react";
import "../../assets/styles/navbar.css";
import dobedo_logo from "../../assets/img/red.png";
import { Link, useNavigate } from "react-router-dom";
// import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  let location = useLocation();
  const serviceRef = useRef();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handlePages = () => {
    const page = location.pathname;

    if (page !== "/") {
      navigate("/");
    }
  };

  function ScrollToTop() {
    // const { pathname } = useLocation();

    window.scrollTo(0, 0);
    // useEffect(() => {
    // }, [pathname]);

    return null;
  }

  return (
    <header className="wrapper">
      <nav className="navbar navbar-custom navbar-expand-lg center-nav navbar-light caret-none pt-lg-0">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand navbar-brand-custom ">
            <Link to="/" onClick={ScrollToTop}>
              <img src={dobedo_logo} alt="" className="w-100 h-auto" />
            </Link>
          </div>
          <div
            className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start ms-auto ${
              show ? "show" : ""
            }`}
          >
            <div className="offcanvas-header d-lg-none">
              <h3 className="text-white fs-30 mb-0">Dobedo</h3>
              <button
                // type="button"
                className="fa-solid fa-xmark custom-close-button"
                onClick={() => setShow(!show)}
              />
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column justify-content-center h-100">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={ScrollToTop}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#services"
                    data-bs-toggle="dropdown"
                    onClick={handlePages}
                  >
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/career"
                    data-bs-toggle="dropdown"
                  >
                    Career
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about" onClick={handlePages}>
                    About
                  </a>
                </li>
                <li class="nav-item  d-flex align-items-center">
                  <Link
                    to="/contact-us"
                    class="btn btn-sm btn-primary rounded contact-us-button"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
              {/* /.navbar-nav */}
              <div className="offcanvas-footer d-lg-none">
                <div>
                  <a className="link-inverse">
                    <span
                      className="__cf_email__"
                      data-cfemail="731a1d151c33161e121a1f5d101c1e"
                    >
                      hello@dobedosoft.com
                    </span>
                  </a>
                  <br />
                  +91 6352 746259
                  <br />
                  <nav className="nav social social-white mt-4">
                    <a
                      href="https://in.linkedin.com/company/dobedo-soft"
                      target="_blank"
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                    <a href="https://www.facebook.com/people/Dobedo-Soft/100088378183383/">
                      <i class="fa-brands fa-square-facebook"></i>
                    </a>

                    <a
                      href="https://www.instagram.com/dobedosoft/"
                      target="_blank"
                    >
                      <i class="fa-brands fa-square-instagram"></i>
                    </a>
                  </nav>
                </div>
              </div>
              {/* /.offcanvas-footer */}
            </div>
            {/* /.offcanvas-body */}
          </div>
          {/* /.navbar-collapse */}
          <div className="navbar-other d-flex ms-auto">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              {/* <li className="nav-item">
                <nav className="nav social social-muted justify-content-end text-end">
                  <a href="#">
                    <i className="uil uil-twitter" />
                  </a>
                  <a href="#">
                    <i className="uil uil-facebook-f" />
                  </a>
                  <a href="#">
                    <i className="uil uil-dribbble" />
                  </a>
                  <a href="#">
                    <i className="uil uil-instagram" />
                  </a>
                </nav>
              </li> */}
              <li className="nav-item d-lg-none">
                {/* <Button variant="primary" onClick={handleShow}>
                  Launch
                </Button> */}
                <button
                  className="hamburger offcanvas-nav-btn"
                  onClick={() => setShow(!show)}
                >
                  <span />
                </button>
              </li>
            </ul>
            {/* /.navbar-nav */}
          </div>
          {/* /.navbar-other */}
        </div>
        {/* /.container */}
      </nav>
      {/* /.navbar */}
    </header>
  );
};

export default Navbar;
