import React from "react";
import discover from "../../assets/img/svg/discover.svg";
import design from "../../assets/img/svg/design.svg";
import build from "../../assets/img/svg/build.svg";
import deliver from "../../assets/img/svg/deliver.svg";

function Process() {
  return (
    <>
      <section className="wrapper bg-light ">
        <div className="container ">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="fs-16 text-uppercase text-muted mb-3">
                HOW IT WORK?
              </h2>
              <h3 className="display-4 mb-10 px-xl-10">
                Four Simple Step To Started Working Process
              </h3>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              data-rellax-speed={1}
              style={{ bottom: "-0.5rem", right: "-2.2rem", zIndex: 0 }}
            />
            <div
              className="shape bg-dot primary rellax w-16 h-17"
              data-rellax-speed={1}
              style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
            />
            <div
              className="row gx-md-5 gy-5 text-center process"
              data-aos="zoom-in"
            >
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={discover}
                      className="icon-svg icon-svg-md text-yellow mb-3"
                      alt=""
                    />
                    <h4>Discover</h4>
                    <p className="mb-2">
                      We shape brands through exploration, applying in-depth
                      research to challenge assumptions at every turn.
                    </p>
                    {/* <a href="#" className="more hover link-yellow">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={design}
                      className="icon-svg icon-svg-md text-red mb-3"
                      alt=""
                    />
                    <h4>Design</h4>
                    <p className="mb-2">
                      Our design approach is to simplify. We embrace the joy in
                      creating something unique that is easy for end users.
                    </p>
                    {/* <a href="#" className="more hover link-red">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={build}
                      className="icon-svg icon-svg-md text-green mb-3"
                      alt=""
                    />
                    <h4>Build</h4>
                    <p className="mb-2">
                      Using modern technologies, we build with efficiency and
                      skill, creating flexible and scalable business-driven
                      solutions.
                    </p>
                    {/* <a href="#" className="more hover link-green">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={deliver}
                      className="icon-svg icon-svg-md text-blue mb-3"
                      alt=""
                    />
                    <h4>Deliver</h4>
                    <p className="mb-2">
                      We take an iterative approach to both our work and our
                      practice, always looking for ways to improve what we do.
                    </p>
                    {/* <a href="#" className="more hover link-blue">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.position-relative */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
}

export default Process;
