// Import the functions you need from the SDKs you need
import { getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBeLgWZfS7qNhMcQnBDKD8hfxRERyJjLF8",
  authDomain: "dobedosoft.firebaseapp.com",
  projectId: "dobedosoft",
  storageBucket: "dobedosoft.appspot.com",
  messagingSenderId: "672844912286",
  appId: "1:672844912286:web:e6c512c0d58b204e964d8f",
  measurementId: "G-NCHXK0B8FB",
};

// Initialize Firebase
const Firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(Firebase);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(Firebase);
export const fireStore = getFirestore(Firebase);
// export const storage = Firebase.storage();
export default Firebase;
