import React from "react";
import smartPhone from "../../assets/img/icons/lineal/smartphone.svg";
import web from "../../assets/img/icons/lineal/browser.svg";
import web2 from "../../assets/img/icons/lineal/browser-2.svg";
import mvp from "../../assets/img/mvp (2).png";
import appDevelopment from "../../assets/img/app-development.png";
import applicationSetting from "../../assets/img/application-settings.png";
import application from "../../assets/img/application.png";

function Services() {
  return (
    <>
      <section className="wrapper bg-light" id="services">
        <div className="container pt-14 pt-md-16">
          <div className="row text-center">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="fs-16 text-uppercase text-muted mb-3">
                What We Do?
              </h2>
              <h3 className="display-4 mb-10 px-xl-10">
                The service we offer is specifically designed to meet your
                needs.
              </h3>
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
          <div className="position-relative">
            <div
              className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
              // data-rellax-speed={1}
              style={{ bottom: "-0.5rem", right: "-2.2rem", zIndex: 0 }}
            />
            <div
              className="shape bg-dot primary rellax w-16 h-17"
              // data-rellax-speed={1}
              style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
            />
            <div
              className="row gx-md-5 gy-5 text-center services"
              data-aos="zoom-in"
            >
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={appDevelopment}
                      className="icon-svg icon-svg-md text-yellow mb-3"
                      alt=""
                    />
                    <h4>Mobile Applications</h4>
                    <p className="mb-2">
                      We provide you with mobile app development services to
                      meet the demands of a constantly changing industry.
                    </p>
                    {/* <a href="#" className="more hover link-yellow">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={application}
                      className="icon-svg icon-svg-md text-red mb-3"
                      alt=""
                    />
                    <h4>Web Applications</h4>
                    <p className="mb-2">
                      Build your Web application with cutting-edge technologies
                      and battle-tested tools.
                    </p>
                    {/* <a href="#" className="more hover link-red">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={applicationSetting}
                      className="icon-svg icon-svg-md text-green mb-3"
                      alt=""
                    />
                    <h4>Backend API Dev.</h4>
                    <p className="mb-2">
                      Build extensible back-end solutions for mobile, web and
                      IOTs that scales with growing business needs.
                    </p>
                    {/* <a href="#" className="more hover link-green">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
              <div className="col-md-6 col-xl-3">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <img
                      src={mvp}
                      className="icon-svg icon-svg-md text-blue mb-3"
                      alt=""
                    />
                    <h4>MVP Development</h4>
                    <p className="mb-2">
                      Get end to end software development services for startups
                      and enterprises.
                    </p>
                    {/* <a href="#" className="more hover link-blue">
                      Learn More
                    </a> */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.position-relative */}
        </div>
        {/* /.container */}
      </section>
    </>
  );
}

export default Services;
