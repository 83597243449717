import React from "react";
import "../../assets/styles/contact.css";

const Contact = () => {
  return (
    <section
      className="wrapper bg-dark angled lower-start pr-0"
      style={{ backgroundColor: "#1D1E25!important" }}
    >
      <div className="row pr-0 mr-0">
        <div className="col-lg-8 mx-auto ">
          <h1 className="display-1 text-white get-in-touch">Get in Touch</h1>

          {/* /nav */}
        </div>
        {/* /column */}
      </div>

      {/* /.container */}
    </section>
  );
};

export default Contact;
