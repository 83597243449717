import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ContactUsPage from "../pages/ContactUsPage";
import AboutUs from "../pages/AboutUs";
import Career from "pages/Career";
import Privacy from "pages/PrivacyPage";

const CustomRoutes = () => {
  return (
    <Routes>
      <Route exact={true} path="/" element={<HomePage />} />
      <Route exact={true} path="/contact-us" element={<ContactUsPage />} />
      <Route exact={true} path="/about-us" element={<AboutUs />} />
      <Route exact={true} path="/career" element={<Career />} />
      <Route exact={true} path="/privacy" element={<Privacy />} />
    </Routes>
  );
};

export default CustomRoutes;
