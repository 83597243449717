import { location_icon, mail_icon, phone_icon } from "../../assets/icons/index";
import React, { useState } from "react";
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import { fireStore } from "../../firebase";
import { useAlert } from "react-alert";

const Map = () => {
  const alert = useAlert();
  const [user, setUser] = useState({
    email: "",
    name: "",
    message: "",
  });

  const [error, setError] = useState({
    email: "",
    name: "",
    message: "",
  });

  const handleChange = (e) => {
    handleValidationOnChange(e.target.name, e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleValidationOnChange = (key, value) => {
    if (value !== "") {
      // errors[key] = "";
      setError({ ...error, [key]: "" });
    }
  };

  const handleValidation = (data) => {
    const errors = {};
    let err = false;
    if (user.email === "" || user.name === "" || user.message === "") {
      errors.email = user.email === "" ? "Email is required" : "";
      errors.name = user.name === "" ? "Name is required" : "";
      errors.message = user.message === "" ? "Message is required" : "";
      setError(errors);
      err = true;
    }
    if (data.email) {
      const pattern =
        // eslint-disable-next-line
        /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      const result = pattern.test(data.email);
      if (result === false) {
        errors.email = "Invalid Email";
        err = true;
      }
    }

    if (data.mobile) {
      // eslint-disable-next-line
      const pattern =
        // eslint-disable-next-line
        /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
      if (pattern.test(data.mobile)) {
        if (data.mobile.length !== 10) {
          errors.mobile = "Please put 10  digit mobile number";
          err = true;
        }
      } else {
        errors.mobile = "Invalid mobile number";
        err = true;
      }
    }

    setError(errors);
    return err;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!handleValidation(user)) {
      try {
        const docRef = await addDoc(collection(fireStore, "contact"), {
          // id: uuid(),
          ...user,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
        if (docRef.id) {
          // alert("Your Details submitted successfully!");
          alert.show("Submitted successfully!", {
            type: "success",
          });
        }
        setUser({
          name: "",
          email: "",
          message: "",
          mobile: "",
          appLink: "",
        });
        // setAppInfo("");
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  return (
    <section className="wrapper bg-light angled upper-end">
      <div className="container pb-11">
        <div className="row mb-14 mb-md-16">
          <div className="col-xl-10 mx-auto mt-n19">
            <div className="card">
              <div className="row gx-0">
                <div className="col-lg-6 align-self-stretch">
                  <div className="map map-full rounded-top rounded-lg-start">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3127.1577770039917!2d72.88439420728798!3d21.238260627814682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2900aa31c0197601%3A0x586bbf48f273fe40!2sDOBEDO%20Soft!5e0!3m2!1sen!2sin!4v1681210444878!5m2!1sen!2sin"
                      style={{ border: 0, width: "100%", height: "100%" }}
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    />
                  </div>
                  {/* /.map */}
                </div>
                {/*/column */}
                <div className="col-lg-6">
                  <div className="p-10 p-md-11 p-lg-14">
                    <div className="d-flex flex-row ">
                      <div>
                        <div className="icon icon-wrapper text-primary fs-20 me-4 mt-n1">
                          <i
                            class="fa-solid fa-house"
                            style={{ color: "#000000" }}
                          ></i>
                        </div>
                      </div>
                      <div className="align-self-start justify-content-start">
                        <h5 className="mb-1">Address</h5>
                        <address>
                          316, Dhara Trade Centre,{" "}
                          <br className="d-none d-md-block" />
                          Mota Varachha, Surat, Gujarat 394101
                        </address>
                      </div>
                    </div>
                    {/*/div */}
                    <div className="d-flex flex-row">
                      <div>
                        <div className="icon icon-wrapper text-primary fs-20 me-4 mt-n1">
                          <i
                            class="fa-sharp fa-solid fa-phone"
                            style={{ color: "#000000" }}
                          ></i>{" "}
                        </div>
                      </div>
                      <div>
                        <h5 className="mb-1">Phone</h5>
                        <p>
                          {/* 00 (123) 456 78 90 <br /> */}
                          <a href="tel:+916352746259"> +91 6352 746259</a>
                        </p>
                      </div>
                    </div>
                    {/*/div */}
                    <div className="d-flex flex-row">
                      <div>
                        <div className="icon icon-wrapper text-primary fs-20 me-4 mt-n1">
                          <i
                            class="fa-solid fa-envelope"
                            style={{ color: "#000000" }}
                          ></i>
                        </div>
                      </div>
                      <div>
                        <h5 className="mb-1">E-mail</h5>
                        <p className="mb-0">
                          <a href="cdn-cgi/l/email-protection.html#8ba8">
                            <a href="mailto:hello@dobedosoft.com">
                              hello@dobedosoft.com
                            </a>
                          </a>
                          {/* <a
                            href="cdn-cgi/l/email-protection.html#f281939c96909d8ab2979f939b9edc919d9f"
                            className="link-body"
                          >
                            sandbox@email.com
                          </a> */}
                        </p>
                      </div>
                    </div>
                    {/*/div */}
                  </div>
                  {/*/div */}
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/* /.card */}
          </div>
          {/* /column */}
        </div>
        {/* /.row */}
        <div className="row">
          <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <h2 className="display-4 mb-3 text-center">Drop Us a Line</h2>
            <p className="lead text-center mb-10">
              Reach out to us from our contact form and we will get back to you
              shortly.
            </p>
            <form
              className="contact-form needs-validation contactForm"
              method="post"
              action="https://sandbox.elemisthemes.com/assets/php/contact.php"
              noValidate=""
              onSubmit={onSubmit}
            >
              <div className="messages" />
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input
                      id="form_name"
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Jane"
                      required=""
                      value={user.name}
                      onChange={handleChange}
                    />
                    <label htmlFor="form_name">First Name *</label>
                    <div className="valid-feedback"> Looks good! </div>
                    {error.name && (
                      <div className="invalid-feedback">
                        {" "}
                        Please enter your first name.{" "}
                      </div>
                    )}
                  </div>
                </div>
                {/* /column */}
                {/* /column */}
                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input
                      id="form_email"
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="jane.doe@example.com"
                      required=""
                      value={user.email}
                      onChange={handleChange}
                    />
                    <label htmlFor="form_email">Email *</label>
                    <div className="valid-feedback"> Looks good! </div>
                    {error.email && (
                      <div className="invalid-feedback">
                        {" "}
                        Please provide a valid email address.{" "}
                      </div>
                    )}
                  </div>
                </div>
                {/* /column */}
                <div className="col-12">
                  <div className="form-floating mb-4">
                    <textarea
                      id="form_message"
                      name="message"
                      className="form-control"
                      placeholder="Your message"
                      style={{ height: 150 }}
                      required=""
                      defaultValue={""}
                      value={user.message}
                      onChange={handleChange}
                    />
                    <label htmlFor="form_message">Message *</label>
                    <div className="valid-feedback"> Looks good! </div>
                    {error.message && (
                      <div className="invalid-feedback">
                        {" "}
                        Please enter your messsage.{" "}
                      </div>
                    )}
                  </div>
                </div>
                {/* /column */}
                <div className="col-12 text-center">
                  <input
                    type="submit"
                    className="btn btn-primary btn-send mb-3 contact-us-button"
                    defaultValue="Send message"
                  />
                  <p className="text-muted">
                    <strong>*</strong> These fields are required.
                  </p>
                </div>
                {/* /column */}
              </div>
              {/* /.row */}
            </form>
            {/* /form */}
          </div>
          {/* /column */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
};

export default Map;
