import React, { useEffect } from "react";
import Serve from "../components/serve";
import NavbarComponent from "../components/navbar/Navbar";
import Hero from "../components/hero/";
import Services from "../components/services";
import Process from "../components/process";
import Testimonial from "../components/testimonial";
import Footer from "../components/footer";
import About from "../components/about";
import Gallery from "components/gallery";
const HomePage = () => {
  useEffect(() => {
    // addScript();
  }, []);
  return (
    <>
      <NavbarComponent />
      <Hero />

      <div className="m-100">
        <Services />
      </div>

      <div className="m-100">
        <Serve />
      </div>

      <Process />

      <Testimonial />
      <div className="m-100">
        <About />
      </div>
      <Gallery />

      <Footer />
    </>
  );
};

export default HomePage;
