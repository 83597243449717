import React from "react";

const Privacy = () => {
  return (
    <div class="container" style={{ paddingTop: "6.25rem" }}>
      <h1>Privacy Policy</h1>

      <p>
        At DOBEDO Soft, we are committed to protecting the privacy of our users.
        This Privacy Policy outlines how we collect, use, disclose, and
        safeguard your personal information. Please read this policy carefully
        to understand our practices regarding your personal information and how
        we will treat it. By accessing or using our services, you agree to this
        Privacy Policy.
      </p>

      <h2>Information We Collect</h2>

      <p>
        We may collect personal information from you in various ways, including
        when you visit our website, use our software, or communicate with us.
        The types of personal information we collect may include:
      </p>

      <ul>
        <li>Your name, email address, and contact information.</li>
        <li>
          Information about your computer or device, such as IP address, browser
          type, and operating system.
        </li>
        <li>
          Usage details, such as the pages you visit and the features you use on
          our website or software.
        </li>
        <li>Any other information you choose to provide to us.</li>
      </ul>

      <h2>How We Use Your Information</h2>

      <p>
        We may use the information we collect from you for various purposes,
        including:
      </p>

      <ul>
        <li>To provide, maintain, and improve our services.</li>
        <li>To personalize your experience and deliver relevant content.</li>
        <li>To respond to your inquiries and provide customer support.</li>
        <li>
          To send you important information, such as updates and notifications.
        </li>
        <li>
          To detect, prevent, and address technical issues or security breaches.
        </li>
        <li>To analyze usage trends and gather statistical information.</li>
        <li>To comply with legal obligations or protect our rights.</li>
      </ul>

      <h2>How We Share Your Information</h2>

      <p>
        We may share your personal information with third parties in the
        following circumstances:
      </p>

      <ul>
        <li>With your consent or at your direction.</li>
        <li>
          With service providers who perform services on our behalf, such as
          hosting, data analysis, and customer support.
        </li>
        <li>
          With our business partners, affiliates, or other third parties for
          purposes such as marketing and advertising.
        </li>
        <li>If required by law or to comply with legal process.</li>
        <li>
          To protect the rights, property, or safety of DOBEDO Soft, our users,
          or others.
        </li>
      </ul>

      <h2>Data Security</h2>

      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, please be aware that
        no method of transmission over the internet or electronic storage is
        100% secure, and we cannot guarantee absolute security of your
        information.
      </p>

      <h2>Third-Party Links</h2>

      <p>
        Our website or software may contain links to third-party websites or
        services that are not owned or controlled by DOBEDO Soft. We have no
        control over and assume no responsibility for the content, privacy
        policies, or practices of any third-party websites or services. We
        strongly advise you to review the privacy policies of any third-party
        websites or services that you visit.
      </p>

      <h2>Children's Privacy</h2>

      <p>
        Our services are not intended for use by individuals under the age of
        13. We do not knowingly collect personal information from children under
        13. If you are a parent or guardian and believe that your child has
        provided us with personal information, please contact us so that we can
        delete such information from our systems.
      </p>

      <h2>Changes to This Privacy Policy</h2>

      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. We
        recommend that you review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>

      <h2>Contact Us</h2>

      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>

      <p>
        DOBEDO Soft
        <br />
        316 Dhara Trade Center
        <br />
        Mahadev Chowk, Surat
        <br />
        Email: hello@dobedosoft.com
        <br />
      </p>
    </div>
  );
};

export default Privacy;
