import React from "react";
import patternImg from "../../assets/img/pattern.png";
import { Link } from "react-router-dom";

const Serve = () => {
  return (
    <section
      className="wrapper pattern-wrapper bg-image section-frame"
      // data-image-src=""
      style={{ backgroundImage: `url(${patternImg})` }}
    >
      <div className="container pt-15 pt-md-17 ">
        <div
          className="row gx-lg-0 gy-10 align-items-center mb-18"
          data-aos="zoom-in"
        >
          <div className="col-lg-6 order-lg-2 ms-lg-auto">
            <div className="card rounded-xl me-lg-6 bg-pale-grape">
              <div className="card-body p-6">
                <div className="d-flex flex-row">
                  <div>
                    <span className="icon btn btn-block btn-lg shadow-xl rounded-xl btn-white pe-none me-4">
                      <span className="number text-grape">01</span>
                    </span>
                  </div>
                  <div>
                    <h3 className="mb-1 text-grape">Visual Storytelling</h3>
                    <p className="mb-0">
                      We create engaging experiences that are meaningful and
                      relevant to your customers.
                    </p>
                  </div>
                </div>
              </div>
              {/*/.card-body */}
            </div>
            {/*/.card */}
            <div className="card rounded-xl ms-lg-13 mt-6 bg-pale-fuchsia">
              <div className="card-body p-6">
                <div className="d-flex flex-row">
                  <div>
                    <span className="icon btn btn-block btn-lg shadow-xl rounded-xl btn-white pe-none me-4">
                      <span className="number text-fuchsia">02</span>
                    </span>
                  </div>
                  <div>
                    <h3 className="mb-1 text-fuchsia">
                      Transparency & Commitment
                    </h3>
                    <p className="mb-0">
                      Transparent expenses, long-term partnerships, committed.
                    </p>
                  </div>
                </div>
              </div>
              {/*/.card-body */}
            </div>
            {/*/.card */}
            <div className="card rounded-xl mx-lg-6 mt-6 bg-pale-green">
              <div className="card-body p-6">
                <div className="d-flex flex-row">
                  <div>
                    <span className="icon btn btn-block btn-lg shadow-xl rounded-xl btn-white pe-none me-4">
                      <span className="number text-green">03</span>
                    </span>
                  </div>
                  <div>
                    <h3 className="mb-1 text-green">Hunger to Succeed</h3>
                    <p className="mb-0">
                      Excellence is contagious, we're winners.
                    </p>
                  </div>
                </div>
              </div>
              {/*/.card-body */}
            </div>
            {/*/.card */}
          </div>
          {/*/column */}
          <div className="col-lg-5 text-inverse">
            <h2 className="display-2 mb-3 text-white">
              Why take this trip with Dobedo soft
            </h2>
            <p className="lead fs-lg pe-lg-5">
              Find out everything you need to know and more about how we create
              Personalized Software Development with Excellence.
            </p>
            <p>
              Dobedo Soft offers personalized software development with a
              dedicated team of experts. We prioritize transparency,
              communication, and support to deliver excellent results. Join us
              for a unique journey in software development.
            </p>
            <Link
              href="/contact-us"
              className="btn btn-white rounded-xl mb-0 contact-us-button text-white"
            >
              Start a project
            </Link>
          </div>
          {/*/column */}
        </div>
        {/*/.row */}
        <div
          className="row gx-lg-0 gy-10 align-items-center text-inverse"
          data-aos="zoom-in"
        >
          <div className="col-lg-6">
            <figure>
              <img
                className="img-auto"
                src="assets/img/illustrations/ui4.png"
                srcSet="./assets/img/illustrations/ui4@2x.png 2x"
                alt=""
              />
            </figure>
          </div>
          {/*/column */}
          {/* <div className="col-lg-5 ms-lg-auto">
            <h2 className="display-2 mb-3 text-white">Our Solutions</h2>
            <p className="lead fs-lg pe-lg-5">
              we offer a range of innovative IT solutions to meet your business
              needs.
            </p>
            <p className="lead fs-lg pe-lg-5">
              Our team of experts is committed to delivering personalized and
              cost-effective solutions that help you achieve your business
              goals.
            </p>
            <div className="row align-items-center counter-wrapper gy-6">
              <div className="col-md-6">
                <h3 className="counter counter-lg mb-1 text-white">99.7%</h3>
                <h5 className="mb-1 text-white">Customer Satisfaction</h5>
                <span className="ratings five text-red" />
              </div>
              <div className="col-md-6">
                <h3 className="counter counter-lg mb-1 text-white">4x</h3>
                <h5 className="mb-1 text-white">New Visitors</h5>
                <span className="ratings five" />
              </div>
            </div>
          </div> */}
          {/*/column */}
        </div>
        {/*/.row */}
      </div>
      {/* /.container */}
    </section>
  );
};

export default Serve;
